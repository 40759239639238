import React, { useContext, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ToggleButton,
} from 'react-bootstrap';
import { ProjectsStateContext, reducerActionType } from './models';
import { Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { useProjectService } from '../../services/ProjectsService';
import { notify } from '../../components/Toast/Toast';
import { Asterisk } from 'react-bootstrap-icons';

const schema = Yup.object().shape({
  Title: Yup.string().required(),
  Bundle: Yup.string()
    .required()
    .test('regex', 'Should looks like com.say.games', (value) => {
      if (value) {
        return /^[a-z\d]+(\.[a-z\d]+){2,10}$/.test(value);
      }
      return false;
    }),
  Platform: Yup.string()
    .required()
    .oneOf(['iOS', 'Android', 'Web'], 'Must be iOS, Android or Web'),
  Notes: Yup.string().optional(),
});

const initialState = {
  Title: '',
  Bundle: '',
  Platform: 'Android',
  // WebStore: 'CrazyGames',
  Notes: '',
};

export const NewGameRequestModal: React.FC = () => {
  const { state, dispatch } = useContext(ProjectsStateContext);

  const { loading, error, requestNewGame } = useProjectService();

  const handleClose = () => {
    dispatch({
      type: reducerActionType.SET_SHOW_REQUEST_MODAL,
      payload: false,
    });
  };

  const onSubmit = (values: FormikValues) => {
    const request = {
      requestTitle: values.Title,
      requestBundle: values.Bundle,
      requestPlatform: values.Platform,
      requestNotes: values.Notes,
    };
    requestNewGame(request).then((resp) => {
      notify('success', resp.message);
      dispatch({
        type: reducerActionType.SET_SHOW_REQUEST_MODAL,
        payload: false,
      });
    });
  };

  useEffect(() => {
    error && notify('error', error);
  }, [error]);

  return (
    <Modal
      show={state.showRequestModal}
      backdrop={'static'}
      onHide={handleClose}
      data-testid="mdlProjectListRequestNewGame"
    >
      <ModalHeader closeButton>
        <ModalTitle>Request New Game</ModalTitle>
      </ModalHeader>
      <Formik
        validationSchema={schema}
        initialValues={initialState}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form noValidate onSubmit={handleSubmit} autoComplete="off">
            <ModalBody>
              <FormGroup className="position-relative">
                <FormLabel>
                  Title
                  <Asterisk
                    width={6}
                    height={6}
                    color="red"
                    className="align-text-top"
                  />
                </FormLabel>
                <FormControl
                  data-testid="txtProjectListModalRequestNewGameTitle"
                  name="Title"
                  value={values.Title}
                  onChange={handleChange}
                  isInvalid={!!errors.Title}
                />
                <FormControl.Feedback type="invalid" tooltip>
                  {errors.Title}
                </FormControl.Feedback>
              </FormGroup>
              <FormGroup className="position-relative mt-3">
                <FormLabel>
                  Bundle
                  <Asterisk
                    width={6}
                    height={6}
                    color="red"
                    className="align-text-top"
                  />
                </FormLabel>
                <FormControl
                  data-testid="txtProjectListModalRequestNewGameBundle"
                  name="Bundle"
                  value={values.Bundle}
                  onChange={handleChange}
                  isInvalid={!!errors.Bundle}
                />
                <FormControl.Feedback type="invalid" tooltip>
                  {errors.Bundle}
                </FormControl.Feedback>
              </FormGroup>
              <small className="form-text text-muted">
                com.game.bundle{' '}
                <i>&ndash; only lowercase letters and numbers</i>
              </small>
              <FormGroup className="position-relative mt-3">
                <FormLabel>Platform</FormLabel>
                <ButtonGroup className="d-flex flex-grow-1">
                  {['Android', 'iOS'].map((os) => (
                    <ToggleButton
                      data-testid={`rdoProjectListModalRequestNewGame${os}`}
                      key={os}
                      id={`radio-${os}`}
                      type="radio"
                      name="Platform"
                      variant={
                        values.Platform === os ? 'primary' : 'outline-primary'
                      }
                      checked={values.Platform === os}
                      value={os}
                      onChange={handleChange}
                    >
                      {os}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
                {/*{*/}
                {/*    values.Platform === 'Web' && (*/}
                {/*        <ButtonGroup className="d-flex flex-grow-1 mt-1">*/}
                {/*            {['crazygames','poki','youtube','saystore'].map((ws) => (*/}
                {/*                <ToggleButton*/}
                {/*                    data-testid={`rdoProjectListModalRequestNewGame${ws}`}*/}
                {/*                    key={ws}*/}
                {/*                    id={`radio-${ws}`}*/}
                {/*                    type="radio"*/}
                {/*                    name="WebStore"*/}
                {/*                    variant={*/}
                {/*                        values.WebStore === ws ? 'primary' : 'outline-primary'*/}
                {/*                    }*/}
                {/*                    checked={values.WebStore === ws}*/}
                {/*                    value={ws}*/}
                {/*                    onChange={handleChange}*/}
                {/*                >*/}
                {/*                    {ws}*/}
                {/*                </ToggleButton>*/}
                {/*            ))}*/}
                {/*        </ButtonGroup>*/}
                {/*    )*/}
                {/*}*/}
                <FormControl.Feedback type="invalid" tooltip>
                  {errors.Platform}
                </FormControl.Feedback>
              </FormGroup>
              <FormGroup className="mt-3">
                <FormLabel>
                  Notes <small>(optional)</small>
                </FormLabel>
                <StyledNotes>
                  <FormControl
                    data-testid="txaProjectListModalRequestNewGameNotes"
                    as="textarea"
                    rows={5}
                    name="Notes"
                    value={values.Notes}
                    onChange={handleChange}
                  />
                </StyledNotes>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              {loading ? (
                <img
                  src="/img/loader.gif"
                  alt="loader"
                  height={24}
                  width={24}
                />
              ) : (
                <>
                  <Button
                    data-testid="btnProjectListModalRequestNewGameClose"
                    variant="secondary"
                    disabled={loading}
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                  <Button
                    data-testid="btnProjectListModalRequestNewGameRequest"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? 'Loading...' : 'Request'}
                  </Button>
                </>
              )}
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const StyledNotes = styled.div`
  textarea {
    resize: none;
  }
`;
